import { fDate } from './formatTime';
export var generateFilterStatClientResponse = function (filter, limit, page) {
    var url = '';
    var nbrKpis = 0;
    filter.forEach(function (element) {
        var _a;
        if (Array.isArray(element.value)) {
            element.value.forEach(function (value, index) {
                url += "".concat(url.length > 0 ? '&' : '').concat(element.type, "[").concat(index, "]=").concat(value);
            });
        }
        else if (element.type === 'kpis' &&
            typeof element.value === 'object' &&
            '_id' in element.value) {
            url += "".concat(url.length > 0 ? '&' : '', "kpis[").concat(nbrKpis, "][kpi]=").concat((_a = element.value) === null || _a === void 0 ? void 0 : _a._id);
            var relatedReponse = element.choices;
            if (relatedReponse) {
                Object.entries(relatedReponse).forEach(function (_a) {
                    var key = _a[0], value = _a[1];
                    if (value) {
                        var encodedValue = encodeURIComponent(key);
                        url += "&kpis[".concat(nbrKpis, "][response][]=").concat(encodedValue);
                    }
                });
            }
            nbrKpis += 1;
        }
        else if (element.type === 'range' &&
            typeof element.value === 'object' &&
            'startDate' in element.value) {
            url += "".concat(url.length > 0 ? '&' : '', "startDate=").concat(fDate(element.value.startDate, 'yyyy-MM-dd'));
            url += "".concat(url.length > 0 ? '&' : '', "endDate=").concat(fDate(element.value.endDate, 'yyyy-MM-dd'));
        }
        else if (element.type !== 'response') {
            url += "".concat(url.length > 0 ? '&' : '').concat(element.type, "=").concat(element.value);
        }
    });
    if (limit)
        url += "".concat(url.length > 0 ? '&' : '', "limit=").concat(limit);
    if (page)
        url += "".concat(url.length > 0 ? '&' : '', "page=").concat(page);
    return url;
};
